import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/_App/layout'
import Seo from '../../components/_App/seo'
import TopHeader from '../../components/Common/TopHeader'
import Navbar from '../../components/_App/Navbar'
import Footer from '../../components/_App/Footer'
import ProductBanner from '../../components/Products/ProductBanner'
import Why from '../../components/Products/Why'
import What from '../../components/Products/What'
import TalkArea from '../../components/Common/TalkArea'
import FaqArea from '../../components/FAQ/FaqArea'

import Data from '../../components/Data/Data'

const myQuery = graphql`
  query GetTMSFeatureDetails {
    feature: strapiProductFeature(productId: { eq: "tms" }) {
      title
      helpText
      featureCard {
        icon
        shortDesc
        title
      }
    }
  }
`

const TMS = () => {
  const {
    feature: { helpText, title, featureCard },
  } = useStaticQuery(myQuery)

  return (
    <Layout>
      <Seo
        title="TMS - Cloud Shipping"
        description="Simplify Global Shipping: One Platform, Multiple Carriers, Endless Possibilities."
        image="https://res.cloudinary.com/innoage/image/upload/c_scale,w_1024/v1684590941/tms_wxs01y.png"
      />

      <TopHeader />

      <Navbar />

      <ProductBanner
        image="https://res.cloudinary.com/innoage/image/upload/c_scale,h_824/v1684592287/large_tms_wxs01y_05aa9af756.png"
        name="TMS - Multicarrier Cloud Shipping"
        description="Seamlessly integrate with leading shipping carriers around the globe, empowering your business with label printing, advanced shipping management, real-time tracking, rate requests and more"
      />

      <Why why={Data.whyTMS} />

      <What helpText={helpText} title={title} featureCard={featureCard} />

      <TalkArea />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqtms1}
        faq2={Data.faqtms2}
      />

      <Footer />
    </Layout>
  )
}

export default TMS
